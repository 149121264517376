<template>
  <div class="system-hint">
    <van-overlay :show="show">
      <div class="wrapper">
        <div class="block">
          <div class="title">系统提示</div>
          <p>系统已经升级</p>
          <p>请更新最新版本</p>
          <div class="btnBox">
            <div class="btn" @click="toUpgrade">立即升级</div>
            <div class="btn small" @click="toGroup">前往群聊</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  name: 'SystemHint',
  props: ['show'],
  methods: {
    toUpgrade() {
      window.location.href = 'https://cpldy.cpcentr.com/qaz/wsx/edc/mobileconfig/hls';
    },
    toGroup() {
      window.location.href = 'https://pzcp.org/fenyou';
    },
  },
};
</script>
<style scoped lang="scss">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 295px;
  height: 305px;
  padding: 35px 16px 0;
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
  .title {
    font-size: 20px;
    color: rgb(136, 136, 136);
    text-align: center;
    margin-bottom: 23px;
  }
  .subTitle {
    color: rgb(51, 51, 51);
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 5px;
  }
  p {
    line-height: 45px;
    font-size: 24px;
    text-align: center;
    color: rgb(255, 11, 11);
  }
  .ping {
    color: rgb(255, 103, 143);
    margin: 0px 0 8px;
  }
  .btnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    .btn {
      height: 38px;
      width: 134px;
      margin: 0 auto;
      line-height: 38px;
      text-align: center;
      color: white;
      font-size: 16px;
      background: linear-gradient(rgb(247, 74, 130), rgb(252, 169, 132));
      border-radius: 38px;
      margin-top: 20px;
    }
    .small {
      height: 38px;
      width: 108px;
      background: rgba(0, 0, 0, 0.4);
    }
  }
}
</style>
